<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="transparent elevation-0">
          <v-card-title primary-title class="text-subtitle-1">
            Mi Meta
            <v-spacer></v-spacer>

            <v-btn 
            	color="primary"
            	@click="initialize4()" 
            	small
            	tile
            >
          		Consultar
          	</v-btn>
          </v-card-title>

          <v-card-text>

            <!-- Titulo de las ventas diarias por escuela -->
          	<v-row justify="center">
          		<v-col cols="12" md="8" lg="6">
		            <v-row>
		            	<v-col cols="12" v-if="ciclo" class="text-center">
		            		<span class="text-h4 black--text"> REPORTE POR CICLO </span>
		            		<br/>
		            		<span class="black--text text-h6"> {{ ciclo }} </span>
		            	</v-col>

		            	<v-col cols="12" v-if="getEscuela == 2">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div>Inscritos Fast
		            		    	<!-- <v-card color="#BD0014" dark class="pa-4 text-center text-h3 shadowCard" @click="tablaVentasCiclo = tablaFast, dialogVentasCiclo = true ">
		            		    		{{ totalFast }}
		            		    	</v-card> -->
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>
                      <v-card-text>
                        <v-progress-circular
                          :rotate="90"
                          :size="160"
                          :width="15"
                          :value="(( totalFast / 388 )*100)"
                          color="red"
                        >
                          <span class="text-h3"><b>{{ totalFast }}</b></span>
                        </v-progress-circular>
                        <v-row class="align-center mt-4 text-h6">
                          <v-col cols="6">
                            <div>Meta</div>
                            <div> 388 </div>
                          </v-col>

                          <v-col cols="6">
                            <div>Faltan</div>
                            <div> {{ 388 - totalFast }} </div>
                          </v-col>
                        </v-row>
                      </v-card-text>        
		            		</v-card>
		            	</v-col>

		            	<v-col cols="12" md="6" lg="6" v-if="getEscuela == 1">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            				<v-spacer></v-spacer>
		            		    <div>Inscritos Inbi
		            		    	<!-- <v-card color="#006487" dark class="pa-4 text-center text-h3 shadowCard" @click="tablaVentasCiclo = tablaInbi, dialogVentasCiclo = true ">
		            		    		{{ totalInbi }}
		            		    	</v-card> -->
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

                      <v-card-text>
                        <v-progress-circular
                          :rotate="90"
                          :size="160"
                          :width="15"
                          :value="(( totalInbi / 701 )*100)"
                          color="blue"
                        >
                          <span class="text-h3"><b>{{ totalInbi }}</b></span>
                        </v-progress-circular>
                        <v-row class="align-center mt-4 text-h6">
                          <v-col cols="6">
                            <div>Meta</div>
                            <div> 701 </div>
                          </v-col>

                          <v-col cols="6">
                            <div>Faltan</div>
                            <div> {{ 701 - totalInbi }} </div>
                          </v-col>
                        </v-row>
                      </v-card-text>

		            		</v-card>
		            	</v-col>
		            </v-row>
          		</v-col>
          	</v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  import XLSX from 'xlsx'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,


     
      desserts: [],

      tablaFast:[],
      tablaInbi:[],

      totalInbi:0,
      totalFast:0,

      fechaini:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      escuela: 1,

      fecha_inicio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fecha_final:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      ciclo: null,

      tablaVentasCiclo:[],

			tituloSemana: '',


    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    async created () {
      await this.initialize4()
    },

    methods: {

    	async initialize4(){
    		await this.initialize()

    	},

    	initialize () {
        this.cargar    = true
        var payload = { fechaini: this.fechaini, escuela: this.escuela }

        this.$http.post('kpi.ventasmomento', payload ).then(response=>{

          this.totalFast        = response.data.totalFast 
          this.totalInbi        = response.data.totalInbi
          this.ciclo            = response.data.ciclo
        	
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    },
  }
</script>